import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import Image from 'gatsby-image'

import './objectSelector.css'

class ObjectSelector extends React.Component {
  render() {
    return (
      <div className="object-selector">
        {this.props.objects.map(object => (
          <Link
            to={`${this.props.path}/${object.uid}${
              this.props.anchor ? '#' + this.props.anchor : ''
            }`}
            key={object.uid}
            className="object-selector__object"
          >
            {object.data.pictures[0] && (
              <div className="object-selector__picture">
                <Image
                  fluid={
                    object.data.pictures[0].picture.localFile.childImageSharp
                      .thumbnail
                  }
                />
              </div>
            )}
            <div className="object-selector__title">
              {object.data.name.text}
            </div>
          </Link>
        ))}
      </div>
    )
  }
}

ObjectSelector.propTypes = {
  objects: PropTypes.array,
  anchor: PropTypes.string,
}

ObjectSelector.defaultProps = {
  objects: [],
}

export default ObjectSelector
