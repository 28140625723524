import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import SEO from '../components/seo'

import Carousel, { Modal, ModalGateway } from 'react-images'

import Comment from '../components/comment'
import Layout from '../components/layout'
import ObjectSelector from '../components/objectSelector'

import i18n from '../i18n/i18n'
import { getTitle } from '../util/title-helper'

import './styles/cabin.css'

const Sauna = ({ data: { sauna, allSaunas }, pageContext }) => {
  const { data } = sauna

  const firstPicture = data.pictures[0] ? data.pictures[0].picture : null
  const restOfPictures = data.pictures
    .slice(1, data.pictures.length)
    .map(pic => pic.picture)

  const lang = pageContext.lang.split('-')[0]

  const [modalState, setModalState] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)

  const openModal = index => {
    setImageIndex(index)
    setModalState(true)
  }

  return (
    <Layout page="sauna">
      <Helmet>
        <title>{getTitle(lang, data.full_name.text)}</title>
      </Helmet>
      <SEO
        title={getTitle(lang, data.full_name.text)}
        lang={lang}
        description={data.description.text}
      />

      <ObjectSelector
        path={`/${lang}/${i18n[lang].sauna.slug}`}
        objects={allSaunas.edges.map(edge => edge.node)}
        anchor="sauna"
      />
      <div className="cabin" id="sauna">
        <h1 className="cabin__name">{data.full_name.text}</h1>
        <div className="cabin__story">“{data.story.text}”</div>
        <div
          className="cabin__description"
          dangerouslySetInnerHTML={{ __html: data.description.html }}
        />
        <div className="cabin__comments">
          {data.comments.map(
            (comment, i) =>
              comment.comment.text && (
                <Comment
                  key={`comment-${i}`}
                  comment={comment.comment.text}
                  name={comment.commentname.text}
                />
              )
          )}
        </div>
        <div className="cabin__pictures">
          <div
            className="cabin__main-picture"
            onClick={() => openModal(0)}
            onKeyPress={() => openModal(0)}
            role="link"
            tabIndex="0"
          >
            {firstPicture && (
              <Image fluid={firstPicture.localFile.childImageSharp.fluid} />
            )}
          </div>

          <div className="cabin__thumbnails">
            {restOfPictures.map((picture, i) => (
              <div
                className="cabin__thumbnail"
                key={picture.localFile.id}
                onClick={() => openModal(i + 1)}
                onKeyPress={() => openModal(i + 1)}
                role="link"
                tabIndex="0"
              >
                <Image fluid={picture.localFile.childImageSharp.thumbnail} />
              </div>
            ))}
          </div>
        </div>

        <ModalGateway>
          {modalState ? (
            <Modal
              onClose={() => setModalState(false)}
              styles={{
                blanket: base => ({
                  ...base,
                  zIndex: 100,
                }),
                positioner: base => ({
                  ...base,
                  zIndex: 100,
                }),
              }}
            >
              <Carousel
                views={data.pictures.map(
                  picture => picture.picture.localFile.childImageSharp.fluid
                )}
                currentIndex={imageIndex}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </Layout>
  )
}

export default Sauna

export const pageQuery = graphql`
  query SaunaBySlug($uid: String!, $lang: String!) {
    sauna: prismicSauna(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      data {
        name {
          text
        }
        full_name {
          text
        }
        story {
          text
        }
        description {
          html
        }
        comments {
          comment {
            text
          }
          commentname {
            text
          }
        }
        pictures {
          picture {
            url
            localFile {
              id
              childImageSharp {
                fluid(maxWidth: 800, srcSetBreakpoints: [800]) {
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
                thumbnail: fluid(
                  maxWidth: 400
                  maxHeight: 400
                  srcSetBreakpoints: [400]
                  cropFocus: CENTER
                ) {
                  src
                  aspectRatio
                  srcSet
                  sizes
                }
              }
            }
          }
        }
      }
    }
    allSaunas: allPrismicSauna(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___order, order: ASC }
    ) {
      edges {
        node {
          uid
          data {
            name {
              text
            }
            pictures {
              picture {
                url
                localFile {
                  childImageSharp {
                    thumbnail: fluid(
                      maxWidth: 400
                      maxHeight: 400
                      srcSetBreakpoints: [400]
                      cropFocus: CENTER
                    ) {
                      src
                      aspectRatio
                      srcSet
                      sizes
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
