import React from "react"
import styled from "styled-components"

const CommentWrapper = styled.div`
  padding: 1em;
  margin-bottom: 1em;

  background-color: white;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
`;

const CommentText = styled.div`
  font-style: italic;
  font-family: Lucida, serif;

  margin-left: 0.6em;
  margin-bottom: 0.5em;

  text-indent: -0.6em
`;

const CommentName = styled.div`
  font-style: italic;
  font-family: Lucida, serif;
  color: rgba(0, 0, 0, 0.4);

  margin-left: 0.6em;
`;

const Comment = props => {
  const { comment, name } = props;

  return (
    <CommentWrapper>
      <CommentText>“{comment}”</CommentText>
      <CommentName>—{name}</CommentName>
    </CommentWrapper>
  )
};

export default Comment;
